<template>
  <div v-if="!state" class="office-request-main">
      <div v-if="user_role>=99" class="app-request" @click="handle_change_state('ManageRequests')">
          <p>ניהול בקשות משרדיות</p>
          <i class="material-icons">settings</i>
      </div>
      <div class="app-request" @click="handle_change_state('HourlyCorrections')">
          <p>תיקוני שעות</p>
          <i class="material-icons">schedule</i>
      </div>
      <div class="app-request" @click="handle_change_state('IncreasingHours')">
          <p>הגדלת שעות</p>
          <i class="material-icons">update</i>
      </div>
      <div class="app-request" @click="handle_change_state('Prita')">
          <p>פריטה</p>
          <i class="material-icons">local_atm</i>
      </div>
      <div class="app-request" @click="handle_change_state('IshurMachala')">
          <p>אישור מחלה לעובד</p>
          <i class="material-icons">medical_services</i>
      </div>
      <div class="app-request" @click="handle_change_state('WorkAccident')">
          <p>תאונת עבודה</p>
          <i class="material-icons">personal_injury</i>
      </div>
      <div class="app-request" @click="handle_change_state('WorkerForms')">
          <p>טפסים לעובד</p>
          <i class="material-icons">content_paste</i>
      </div>
      <div class="app-request" @click="handle_change_state('EmployeeCard')">
          <p>כרטיס עובד</p>
          <i class="material-icons">badge</i>
      </div>
      <div class="app-request" @click="handle_change_state('TransferEmployee')">
          <p>העברת עובד</p>
          <i class="material-icons">transfer_within_a_station</i>
      </div>
      <div class="app-request" @click="handle_change_state('Piturim')">
          <p>פיטורים</p>
          <i class="material-icons">work_off</i>
      </div>
      <div class="app-request"  @click="handle_change_state('Shimua')">
          <p>שימוע</p>
          <i class="material-icons">groups</i>
      </div>
      <div class="app-request" @click="handle_change_state('Itpatrut')">
          <p>התפטרות</p>
          <i class="material-icons">power_settings_new</i>
      </div>
      <div class="app-request" @click="handle_change_state('Mifraha')">
          <p>קבלת מפרעה</p>
          <i class="material-icons">credit_card</i>
      </div>
      <div class="app-request" @click="handle_change_state('UpdatePersonalDetails')">
          <p>עדכון פרטים אישיים</p>
          <i class="material-icons">account_box</i>
      </div>
      <div class="app-request" @click="handle_change_state('BankAccount')">
          <p>שינוי פרטי חשבון בנק</p>
          <i class="material-icons">account_balance</i>
      </div>
      <div class="app-request" @click="handle_change_state('TemporaryWorker')">
          <p>שעות עבודה לעובד זמני</p>
          <i class="material-icons">more_time</i>
      </div>
      <div class="app-request" @click="handle_change_state('Hakafa')">
          <p>הקפה</p>
          <i class="material-icons">motion_photos_on</i>
      </div>
      <div class="app-request" @click="handle_change_state('GeneralRequest')">
          <p>בקשה כללית</p>
          <i class="material-icons">grid_view</i>
      </div>
      <div class="app-request" @click="handle_change_state('UpdateEmail')">
          <p>עדכון דוא"ל</p>
          <i class="material-icons">mail</i>
      </div>
      <div class="app-request" @click="handle_change_state('Cycle')">
          <p>מחזור בקבוקים</p>
          <i class="material-icons">recycling</i>
      </div>
      <div class="app-request" @click="handle_change_state('OfficeEquipment')">
          <p>ציוד משרדי</p>
          <i class="material-icons">square_foot</i>
      </div>
  </div>
  <div v-if="state" class="office-request-state">
     <component :docId="id" :is="state" />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from '@vue/runtime-core'
import { onBeforeRouteLeave } from 'vue-router'
import store from '../../../../store'


export default {
    props:['id'],
    components:{
        HourlyCorrections:defineAsyncComponent(()=>import('../components/user_side/HourlyCorrections.vue')),
        IncreasingHours:defineAsyncComponent(()=>import('../components/user_side/IncreasingHours.vue')),
        Prita:defineAsyncComponent(()=>import('../components/user_side/Prita.vue')),
        IshurMachala:defineAsyncComponent(()=>import('../components/user_side/IshurMachala.vue')),
        WorkAccident:defineAsyncComponent(()=>import('../components/user_side/WorkAccident.vue')),
        WorkerForms:defineAsyncComponent(()=>import('../components/user_side/WorkerForms.vue')),
        EmployeeCard:defineAsyncComponent(()=>import('../components/user_side/EmployeeCard.vue')),
        TransferEmployee:defineAsyncComponent(()=>import('../components/user_side/TransferEmployee.vue')),
        Piturim:defineAsyncComponent(()=>import('../components/user_side/Piturim.vue')),
        Shimua:defineAsyncComponent(()=>import('../components/user_side/Shimua.vue')),
        Itpatrut:defineAsyncComponent(()=>import('../components/user_side/Itpatrut.vue')),
        Mifraha:defineAsyncComponent(()=>import('../components/user_side/Mifraha.vue')),
        UpdatePersonalDetails:defineAsyncComponent(()=>import('../components/user_side/UpdatePersonalDetails.vue')),
        BankAccount:defineAsyncComponent(()=>import('../components/user_side/BankAccount.vue')),
        TemporaryWorker:defineAsyncComponent(()=>import('../components/user_side/TemporaryWorker.vue')),
        Hakafa:defineAsyncComponent(()=>import('../components/user_side/Hakafa.vue')),
        GeneralRequest:defineAsyncComponent(()=>import('../components/user_side/GeneralRequest.vue')),
        ManageRequests:defineAsyncComponent(()=>import('../components/manage_side/ManageRequests.vue')),
        UpdateEmail:defineAsyncComponent(()=>import('../components/user_side/UpdateEmail.vue')),
        Cycle:defineAsyncComponent(()=>import('../components/user_side/Cycle.vue')),
        OfficeEquipment:defineAsyncComponent(()=>import('../components/user_side/OfficeEquipment.vue'))
    },
    setup(){
        
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))
        const state=ref(null)
        const handle_change_state=(state_name)=>{
            state.value = state_name
        }

        onBeforeRouteLeave((to,from,next)=>{
            if(!state.value){
                next()
            }else{
                if(to.fullPath=='/manage-request-edit'){
                    next()
                    return
                }
                state.value=null
                next(false)
            }
        })

        const init_state_from_LS=()=>{
            var state_saved = localStorage.getItem('office_request_state');
            if(state_saved){
                state.value = state_saved
                console.log(state.value);
                localStorage.removeItem('office_request_state')
            }
        }
        
        init_state_from_LS()
        return{
            handle_change_state,
            state,
            user_role
        }
    },
}
</script>

<style scoped>
    .office-request-main{
        padding: 5px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns:repeat(auto-fill,minmax(250px, 1fr));
        grid-auto-rows: 200px;
        grid-gap: 5px;
        overflow-y: auto;
    }
    @media screen and (max-width: 600px) {
        .office-request-main{
             grid-template-columns:repeat(auto-fill,minmax(140px, 1fr));
        }
    }
    .office-request-state{
        width: 100%;
        height: 100%;
    }
    .app-request{
        background: var(--secondary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        border-radius: 15px;
    }
    .app-request:hover{
        transform:scale(1.01);
        color: rgb(75, 75, 199);
    }
    .app-request p{
        text-align: center;
        font-size: 1.9rem;
    }
    i{
        font-size: 5rem;
    }
</style>